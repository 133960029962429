<template>

	<script type="application/javascript" src="https://stgstdpay.inicis.com/stdjs/INIStdPay_close.js" charset="UTF-8"></script>

</template>

<script>
export default {
	name: "closePg",
	created() {
		this.$loadScript("https://stgstdpay.inicis.com/stdjs/INIStdPay_close.js")
	},
}
</script>

<style scoped>

</style>